@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,600,800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



body{
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  letter-spacing: .35px; 
}

.about-banner{
  margin: 0 auto;
  font-size: 16;
  color: #fff;
  margin-top: 24px;
  margin-bottom: 2px;
	max-width: 1299px;
}

.about-section{
  padding: 2.5rem 0rem;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1500px;
  margin: 0 auto;
}

.headline-section{
  max-width: 1500px;
  margin: 0 auto;
}

@media (min-width:768px){
  div .mine{
    padding-top: 25%;
  }
  .about-banner{
    padding: 0px 24px;
  }
  button .live-button{
    margin-left: 0px;
  }
  .skill-tag{
    max-width: 45%;
    padding: 0px 12px;
  }
}

@media (max-width: 1200px){
  div .mine{
    padding-top: 50%;
  }
}

.projectBtnSection{
  display: flex;
  justify-content: start;
  padding-top: 0px;
	margin-top: 0px;
}

@media (max-width: 768px){
  .projectBtnSection{
    justify-content: center;
  }
  
  div .mine{
    padding-top: 60%;
  }
  
  p.headline{
    text-align: center;
  } 

  .skill-tag{
    max-width: none;
    padding: 0px 0px;
		text-align: center;
  }
  .social-footer{
    padding-bottom: 24px !important; 
  }
  div.form-group{
    margin-left: 30px;
  }
  div.reply.message{
    margin-right: 5px;
  }
  .about-section{
    flex-direction: column;
		padding-top: 8px;
  }
}


.align-auto{
  margin: 0 auto;
}

.buttonIcon{
  cursor: pointer;
}

.blog-highlight{
  max-width: 1500px;
  margin: 0rem auto 2rem auto;
  background-color: #131313;
  color: #fff;
  z-index: 1;
  box-shadow: -0.125rem 0 1rem #000;
  transition: 0.3s ease-in-out;
  border-radius: 16px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 12px;
  padding-top: 20px;
  border: 1px solid white;
}

.blog-highlight:hover{
  cursor: pointer;
}

img.rounded-circle{
  box-shadow: 0 4px 2px -2px rgba(0,0,0,.2); 
}

img.d-block.w-100.img-thumbnail{
  box-shadow: 0 4px 2px -2px rgba(0,0,0,.2);
}

.buttonIcon{
  display: inline;
	padding: 8.5px;
  transition: 0.3s ease-in-out;
	border-radius: 50%;
}

.buttonIcon:hover{
  background-color: #fff;
  border-radius: 50%;
}

span.carousel-control-next-icon:hover{
  width: 23px;
  height: 30px;
  border-radius:8px;
  transition: 0.2s ;
}
span.carousel-control-prev-icon:hover{
  width: 23px;
  height: 30px;
  border-radius:8px;
  transition: 0.2s;
}
span.carousel-control-next-icon{
  transition:  0.2s ease-in-out;
  background-color: #131313;
  border-radius:8px;
}
span.carousel-control-prev-icon{
  transition:  0.2s ease-in-out;
  background-color: #131313;
  border-radius:8px;
}

.carouselProjects{
  margin: 0 auto;
}

.img-thumbnail{
  box-shadow: 0 4px 2px -2px rgba(0,0,0,.2);
  -webkit-filter: brightness(80%);
          filter: brightness(80%);
  max-height: 500px;
}

@-webkit-keyframes zoomThumbnail {
  0% {
      transform: scale(1,1);
  }
  50% {
      transform: scale(1.1,1.1);
  }
  100% {
      transform: scale(1,1);
  }
}

@keyframes zoomThumbnail {
  0% {
      transform: scale(1,1);
  }
  50% {
      transform: scale(1.1,1.1);
  }
  100% {
      transform: scale(1,1);
  }
}

div.active.carousel-item{
  box-shadow: 0 4px 2px -2px rgba(0,0,0,.2);
}

.social-footer{
  display: flex;
  justify-content: center; 
  margin: 0px;
  padding-bottom: 12px; 
  padding-left: 0px;
}

.resume-button:hover{
  background-color: #131313;
  padding-right: 22px;
  padding-left: 22px;
}

.projects-banner{
  font-weight: 600; 
  color: #fff; 
  text-align: center;
}

p#blogIntro::first-letter{
  font-weight: 500;
  font-size: 40px;
  color: #35c958;
}

div.modal-header, div.modal-body, div.modal-footer{
  color: #fff;
  background-color: #131313;
  border-radius: 0;
  border: none;
}

div.modal-header{
  align-items: center;
  color: #6DDBAF;
}

.modal-close{
  cursor: pointer;
}


div.modal-footer{
  border-top: 1px solid #6DDBAF;
  display: flex;
  justify-content: center;
}

hr{
  color: #6DDBAF;
  background-color: #6DDBAF;
}

.close {
  font-size: 1.75rem;
  color: #6DDBAF;
  transition: 0.2s ease-in;
  opacity: 0.8;
}
.close:hover {
  font-size: 1.75rem;
  color: #fff;
  opacity: 1;
}

.close-modal{
  background-color: #6DDBAF;
  color: #000;
  border: none;
  transition: 0.3s ease-in-out;
  font-weight: 600;
}

.close-modal:hover{
  background-color: #fff;
  color: #131313;
}

.view-code{
  width: 165px;
  background-color: #000000; 
  font-weight: 600;
  border-radius: 6px;
  border: none;
  outline: none;
  color: white;
  padding: 0.25rem 0.75rem;
}

.view-code:hover{
  opacity: 0.72;
}

.card1-list{
  max-width: 1500px;
  display: flex; 
  padding: 2rem 15px;
  margin: 0 auto;
  overflow-y: auto;
}

.cards-column{
  margin: 0 auto;
  padding: 0px;
}

.card1{
  overflow: hidden;
  position: relative;
  flex-direction: column;
  height: 420px;
  width: 400px;
  min-width: 256px;
  padding: 1.25rem;
  border-radius: 16px;
  background-color: #131313;
  color: #fff;
  box-shadow: -0.125rem 0 1.25rem #000;
  transition: .3s;
  border: 1px solid white;
  cursor: pointer;
}

.card1:hover{
  transform: translateY(-1.5rem);
}

.card1:hover~.card1{
  transform: translateX(120px);
}

.card1:not(:first-child){
  margin-left: -60px;
}

video {
  -webkit-filter: brightness(80%);
          filter: brightness(80%);
}

.modalImgContainer{
  display: inline-block; 
  overflow: hidden;
  max-height: 500px;
  margin: 0 auto;
}

#background-video{
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: absolute;
}

.nav{
  position: fixed;
  top: 0;
  width: 100%;
  transition-timing-function: 0.4s ease-in-out !important;
  -webkit-transition: 0.4s ease-in-out !important;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-black{
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);
}

ul#horizontal-list{
  padding: 0px;
	margin: 0px;
  list-style: none;
  display: flex;
	justify-content: space-around;
	align-items: center;
	width: 319px;
}

ul#horizontal-list li {
  display: inline;
  padding: 8.5px;
}

.messages {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.message {
  border-radius: 20px;
  padding: 8px 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: inline-block;
  margin-bottom: 12px;
	max-width: 496px;
}

.yours {
  align-items: flex-start;
}

.mine {
  align-items: flex-end;
}

.mine .message {
  color: white;
  background: linear-gradient(to bottom, #35c958 0%, #34c859 100%);
  background-attachment: fixed;
  position: relative;
  opacity: 0;
  margin-right: 105px;
}


/* 
.mine .message.last:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: -8px;
  height: 20px;
  width: 20px;
  background: linear-gradient(to bottom, #35c958 0%, #34c859 100%);
  background-attachment: fixed;
  border-bottom-left-radius: 15px;
}

.mine .message.last:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: -10px;
  width: 10px;
  height: 20px;
  backdrop-filter: blur(35px);
  background: linear-gradient(to bottom, #35c958 0%, #34c859 100%);
  border-bottom-left-radius: 10px;
  transform: translateY(4px) translateX(-10px);
} */

div.yours.messages{
  transform: translateY(-140px);
}

.yours {
  align-items: flex-start;
}

.yours .message {
  margin-right: 25%;
  background-color: #eee;
  position: relative;
}

.yours .message.last:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: -7px;
  height: 20px;
  width: 20px;
  background: #eee;
  border-bottom-right-radius: 15px;
}

.yours .message.last:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-right-radius: 10px;
}

textarea.form-control {
  border-radius: 16px;
  background: transparent;
  background-color: #131313;
  color: #fff;
  min-height: 38px;
}
